import BaseBean from "@/utils/BaseBean";

export interface IContentEngineDataObj {
    utilInst:ContentEngineUtil
    contentEngineParams:any
}
export default class ContentEngineUtil extends BaseBean{
    public dataObj:IContentEngineDataObj;

    constructor(proxy:any,dataObj:IContentEngineDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public initContentEngineParams(contentEngineParams:any):void{

    }
}