import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, computed} from 'vue';
import ContentEngineUtil ,{IContentEngineDataObj} from "./contentEngineUtil";
export default defineComponent ({
    name: "contentEngine",
    props:{
        contentEngineParams: {
            type: Object,
            default:()=>{return{}}
        },
    },
    title: "content",
    components: {},
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let contentEngineParams=props.contentEngineParams;
        let dataObj:IContentEngineDataObj=reactive<IContentEngineDataObj>({
            utilInst:{} as any,
            contentEngineParams:{}
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ContentEngineUtil(proxy,dataObj);
            dataObj.utilInst.initContentEngineParams(contentEngineParams);
        })
        onMounted(()=>{

        })
        return{
            ...toRefs(dataObj)
        }
    }
});